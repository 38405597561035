.article {
  font-family: 'PT Serif', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.4px;

  h3 {
    font-size: 27px;
    line-height: 34px;
    color: #866241;

    &:not(:first-child) {
      margin-top: 70px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  p,
  li {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  ul {
    li {
      position: relative;

      &:before {
        content: '•';
        color: #866241;
        position: absolute;
        top: 0;
        left: -32px;
      }
    }
  }

  @include respond-below('desktop') {
    h3 {
      &:not(:first-child) {
        margin-top: 50px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    ul {
      li {
        &:before {
          left: -26px;
        }
      }
    }
  }

  @include respond-below('tablet') {
    font-size: 15px;
    line-height: 20px;

    h3 {
      font-size: 16px;
      line-height: 20px;

      &:not(:first-child) {
        margin-top: 30px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    p,
    li {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    ul {
      li {
        &:before {
          left: -22px;
        }
      }
    }
  }
}
