.is-services-page {
  @include respond-above('tablet') {
    .header-overlay.is_sticky {
      background-color: transparent;
    }

    .header.is_sticky .header-logo {
      opacity: 0;
    }
  }
}

.services {
  padding: 230px 0 0;
  overflow: hidden;

  @include respond-below('desktop') {
    padding: 280px 0 0;
  }

  @include respond-below('tablet') {
    padding: 240px 0 0;
  }
}

.services-header {
  margin-bottom: 130px;

  @include respond-below('tablet') {
    margin-bottom: 80px;
  }
}

.services-body {
  max-width: 1060px;
  margin: 0 auto;
  position: relative;

  @include respond-below('desktop') {
    padding: 0 80px;
  }

  @include respond-below('tablet') {
    padding: 0 15px;
  }
}

.services-wrapper {
  position: relative;
}

.service-case {
  will-change: transform;

  .service-detail__close {
    display: none;
  }
}
