.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  z-index: 100;
  overflow: hidden;
	display: flex;
	align-items: center;
  justify-content: center;

  &__percentage {
    color: #fff;
    font-size: 150px;
    font-weight: 600;
    line-height: 1;
    font-family: 'NewYorkSmall', serif;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    transition: .3s ease-out;

    &-value {
      position: relative;
      z-index: 2;
    }

    &-text {
      color: #5c5c5c;
      font-size: 55px;
      line-height: 55px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__line {
    max-width: 740px;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #262626;
    transition: .3s ease-out;
  }

  &__bar {
    width: 0;
    height: 100%;
    background-color: #5c5c5c;
  }
  
  &.is_preloader_hide {
    .preloader__percentage,
    .preloader__line {
      opacity: 0;
    }
  }

  &.is_blind_hide {
    .preloader-blind__line {
      transform: translate3d(100%, 0, 0);
    }
  }

  @include respond-below('desktop') {
    &__line {
      width: auto;
      left: 185px;
      right: 185px;
    }
  }

  @include respond-below('tablet') {
    &__percentage {
      font-size: 60px;

      &-text {
        font-size: 40px;
      }
    }

    &__line {
      left: 60px;
      right: 60px;
    }
  }
}

.preloader-blind {
  &__line {
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    transition: 1s transform cubic-bezier(0.165, 0.64, 0.44, 1);
  }
}
