.footer {
  padding: 95px 0 55px;
  overflow: hidden;
  text-align: center;

  @include respond-below('desktop') {
    padding-bottom: 30px;
  }

  @include respond-below('tablet') {
    padding: 40px 0 30px;
  }
}

.footer-address {
  color: #be9d7f;
  font-family: 'NewLetterGothicC', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1.44px;
  margin-bottom: 90px;

  @include respond-above('desktop') {
    transition: .3s ease-out;

    &:hover {
      opacity: .6;
    }

    &:active {
      opacity: .3;
    }
  }

  @include respond-below('tablet') {
    max-width: 255px;
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto 35px;

    br {
      display: none;
    }
  }
}

.footer-contact {
  display: flex;

  &__col {
    width: 50%;
    height: 315px;
    padding-top: 42px;
    display: flex;

    &:first-child {
      justify-content: flex-end;
      padding-right: 100px;
      border-right: 1px solid rgba(255, 255, 255, .1);
    }

    &:nth-child(2) {
      padding-left: 100px;
      justify-content: flex-start;
    }
  }

  &__box {
    width: 170px;
    color: #fff;
    font-family: 'NewLetterGothicC', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    text-align: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;

    svg {
      fill: currentColor;
    }
  }

  &__phone {
    display: inline-block;
    margin-bottom: 25px;
  }

  &__text {
    color: #be9d7f;
  }

  @include respond-above('desktop') {
    &__phone {
      transition: .3s ease-out;

      &:hover {
        opacity: .6;
      }

      &:active {
        opacity: .3;
      }
    }
  }

  @include respond-below('tablet') {
    &__col {
      height: 170px;
      padding-top: 30px;

      &:first-child {
        padding-right: 25px;
      }

      &:nth-child(2) {
        padding-left: 25px;
      }
    }

    &__box {
      width: 112px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.96px;
    }

    &__icon {
      margin-bottom: 20px;

      .icon-phone-footer {
        width: 12px;
        height: 22px;
      }
    }

    &__phone {
      margin-bottom: 15px;
    }
  }
}

.footer-bottom {
  max-width: 1050px;
  margin: 0 auto;
  padding-top: 50px;
  font-family: 'NewLetterGothicC', sans-serif;
  color: rgba(255, 255, 255, .2);
  text-transform: uppercase;
  letter-spacing: 1.37px;
  border-top: 1px solid rgba(255, 255, 255, .1);

  @include respond-above('tablet') {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
  }

  @include respond-below('desktop') {
    padding-top: 30px;
  }

  @include respond-below('tablet') {
    font-size: 12px;
    line-height: 16px;
    padding-top: 20px;
    text-align: center;
  }
}

.footer-copy {
  display: flex;

  svg {
    fill: currentColor;
    margin: 0 13px;
  }

  @include respond-below('tablet') {
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
  }
}

.footer-dev {
  @include respond-above('desktop') {
    transition: .3s ease-out;

    &:hover {
      color: rgba(255, 255, 255, .3);
    }

    &:active {
      color: rgba(255, 255, 255, .1);
    }
  }
}
