.services-grid {
  @include respond-above('tablet') {
    display: flex;
    flex-wrap: wrap;

    &__col {
      width: calc(50% - 55px);
      margin-bottom: 110px;

      &:nth-child(odd) {
        margin-right: 110px;
      }
    }

    @include respond-below('desktop') {
      &__col {
        width: calc(50% - 15px);
        margin-bottom: 30px;

        &:nth-child(odd) {
          margin-right: 30px;
        }
      }
    }
  }

  @include respond-below('tablet') {
    &__col {
      margin-bottom: 15px;
    }
  }
}
