.is-animate-show {
  .hero-presentation,
  .hero-scrolldown,
  .hero-feedback {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.hero {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0a0d14;
}

.hero-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0a0d14;
    opacity: .8;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #000;
  }
}

.hero-container {
  position: relative;
}

.hero-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px 65px;

  @include respond-below('desktop') {
    padding: 0 50px 65px;
  }

  @include respond-below('tablet') {
    flex-direction: column-reverse;
    padding: 30px;
  }
}

.hero-presentation {
  opacity: 0;
  transition: .8s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
  transition-delay: 1s;

  @include respond-above('tablet') {
    min-width: 170px;
    transform: translate3d(-80px, 80px, 0);

    &_mobile {
      display: none;
    }
  }

  @include respond-below('tablet') {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    transform: translate3d(0, 80px, 0);

    &_desktop {
      display: none;
    }
  }
}

.hero-button {
  font-size: 18px;
  font-weight: 400;
  font-family: 'NewLetterGothicC', sans-serif;
  line-height: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &__icon {
    margin-right: 35px;

    svg {
      fill: currentColor;
    }
  }

  @include respond-above('desktop') {
    transition: .3s ease-out;

    &:hover {
      opacity: .6;
    }

    &:active {
      opacity: .3;
    }
  }

  @include respond-below('tablet') {
    font-size: 16px;

    &__icon {
      margin-right: 14px;
    }

    &_feedback {
      padding-bottom: 12px;
      border-bottom: 1px solid #866241;
    }
  }
}

.hero-scrolldown {
  cursor: pointer;
  opacity: 0;
  transform: translate3d(0, 80px, 0);
  transition: .8s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
  transition-delay: 1s;

  svg {
    fill: currentColor;
    will-change: opacity;
    transition: .3s ease-out;
  }

  @include respond-above('desktop') {
    &:hover {
      svg {
        opacity: .6;
      }
    }

    &:active {
      svg {
        opacity: .3;
      }
    }
  }

  @include respond-below('tablet') {
    margin-top: 45px;

    .icon-scrolldown {
      width: 10px;
      height: 6px;
    }
  }
}

.hero-feedback {
  opacity: 0;
  transition: .8s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
  transition-delay: 1s;

  @include respond-above('tablet') {
    min-width: 170px;
    transform: translate3d(80px, 80px, 0);
  }

  @include respond-below('tablet') {
    transform: translate3d(0, 80px, 0);
  }
}

.hero-wrapper {
  padding: 110px 0 0;

  @include respond-below('desktop') {
    padding: 30px 0 0;
  }

  @include respond-below('tablet') {
    padding: 15px 0 0;
  }
}

.hero-about {
  color: #ffffff;
  padding: 115px;
  text-align: center;
  background-color: #0a0d14;
  margin-bottom: 110px;

  &__title {
    font-family: 'NewYorkSmall', serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
    margin: 0 0 70px;
  }

  p {
    color: #9f7650;
    font-size: 27px;
    line-height: 35px;
    font-weight: 400;
    letter-spacing: -0.54px;
  }

  @include respond-below('desktop') {
    padding: 70px 30px;
    margin-bottom: 30px;

    &__title {
      margin: 0 0 60px;
    }

    p {
      max-width: 525px;
      margin: 0 auto;
    }

    br {
      display: none;
    }
  }

  @include respond-below('tablet') {
    padding: 40px 25px;

    &__title {
      font-size: 25px;
      line-height: 30px;
      margin: 0 0 30px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.hero-card {
  position: relative;
  height: 730px;
  padding: 135px;
  background-color: #0a0d14;
  margin-bottom: 110px;

  &__image {
    position: absolute;
    left: 50%;
    bottom: 0;

    img {
      max-height: 767px;
      display: block;
    }
  }

  &__title {
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
    font-family: 'NewYorkSmall', serif;
    letter-spacing: -0.6px;
    margin-bottom: 50px;
  }

  &__position {
    height: 62px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'NewLetterGothicC', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin-bottom: 160px;
  }

  @include respond-above('desktop') {
    &_type_right {
      .hero-card__image {
        left: 115px;
      }

      .hero-card__title,
      .hero-card__position,
      .hero-card__more {
        padding-left: calc(100% - 340px);
      }
    }
  }

  @include respond-below('desktop') {
    height: 520px;
    padding: 110px 45px;
    margin-bottom: 30px;

    &__image {
      left: auto;
      right: 20px;

      img {
        max-height: 532px;
      }
    }

    &__title {
      position: relative;
      z-index: 2;
    }

    &__position {
      margin-bottom: 50px;
    }

    @include respond-above('tablet') {
      &_type_right {
        .hero-card__image {
          left: 15px;
          right: auto;
        }

        .hero-card__title,
        .hero-card__position,
        .hero-card__more {
          padding-left: calc(100% - 280px);
        }
      }
    }
  }

  @include respond-below('tablet') {
    padding: 40px;
    height: 530px;
    margin-bottom: 15px;

    &__title {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 30px;
    }

    &__position {
      height: 44px;
    }

    &__more {
      position: absolute;
      bottom: 40px;
      left: 40px;
    }

    &__image {
      left: 0;
      right: 0;

      img {
        height: 340px;
        margin: 0 auto;
      }
    }
  }
}
