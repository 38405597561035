.service-detail {
  color: #000;
  padding: 90px 165px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border: 1px solid #eeeeee;
  will-change: transform;
  transition: .3s ease-out;

  &:hover {
    transform: translate3d(0, -50px, 0);
  }

  &__close {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 64px;
    right: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 50%;

    .icon-close {
      opacity: .2;
      transition: .3s ease-out;
    }
  }

  &__date {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    margin-bottom: 25px;
  }

  &__title {
    color: #111111;
    font-family: 'NewYorkSmall', serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.64px;
    margin-bottom: 35px;
  }

  &__position {
    height: 62px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'NewLetterGothicC', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 65px;
  }

  &__form {
    margin-top: 160px;
  }

  @include respond-above('desktop') {
    &__close {
      &:hover {
        .icon-close {
          transform: rotate(180deg);
        }
      }
    }
  }

  @include respond-below('desktop') {
    padding: 50px;

    &__close {
      top: 40px;
      right: 30px;
      background-color: transparent;
    }

    &__title {
      font-size: 45px;
      line-height: 45px;
    }

    &__position {
      margin-bottom: 45px;
    }

    &__form {
      margin-top: 50px;
    }
  }

  @include respond-below('tablet') {
    padding: 40px;

    &__close {
      width: 40px;
      height: 40px;
      top: 35px;
      right: 20px;

      .icon-close {
        width: 12px;
        height: 12px;
      }
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 30px;
    }

    &__date {
      font-size: 14px;
      letter-spacing: 1.12px;
      margin-bottom: 20px;
    }

    &__position {
      height: 50px;
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}
