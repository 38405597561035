.link {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 14px;
  font-family: 'NewLetterGothicC', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.44px;
  border-bottom: 1px solid #866241;

  @include respond-above('desktop') {
    position: relative;
    
    &:hover {
      border-color: transparent;

      &:before {
        width: 100%;
        transition: .3s .1s ease-out;
      }

      &:active {
        opacity: .3;
        transition: .3s opacity ease-out;
      }
    }

    &:before {
      content: '';
      width: 0;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: #866241;
    }
  }

  @include respond-below('tablet') {
    font-size: 16px;
    line-height: 20px;
  }
}
