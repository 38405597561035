.button {
  height: 62px;
	border: none;
  outline: none;
  position: relative;
  font-family: 'NewLetterGothicC', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.44px;
  padding-left: 30px;
  padding-right: 30px;
	text-decoration: none;
  user-select: none;
  background-color: transparent;

  &__text {
    width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
    align-items: center;
    transition: 0.75s 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &_theme_border_gold {
    color: #000000;
    border: 1px solid #866241;
  }

  &_block {
    width: 100%;
    display: block;
  }

  @include respond-above('desktop') {
    transition: .3s ease-out;
    
    &:after {
      position: absolute;
      content: attr(data-title);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transform: translateY(35%);
      transition: 0.25s ease-out;
    }

    &__borders {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
    }

    &__border {
      position: absolute;
      background-color: #000000;

      &_top {
        top: 0;
        width: 100%;
        height: 1px;
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.25s cubic-bezier(0.95, 0.05, 0.795, 0.035);
      }

      &_bottom {
        bottom: 0;
        width: 100%;
        height: 1px;
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.65s 0.25s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &_left {
        left: 0;
        width: 1px;
        height: 100%;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: 0.65s 0.25s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &_right {
        right: 0;
        width: 1px;
        height: 100%;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: 0.25s cubic-bezier(0.95, 0.05, 0.795, 0.035);
      }
    }

    &:hover {
      .button__text {
        opacity: 0;
        transform: translateY(-25%);
        transition: 0.25s ease-out;
      }

      .button__border {
        &_top,
        &_bottom {
          transform: scaleX(1);
        }

        &_left,
        &_right {
          transform: scaleY(1);
        }

        &_left,
        &_bottom {
          transition: 0.25s cubic-bezier(0.95, 0.05, 0.795, 0.035);
        }

        &_right,
        &_top {
          transition: 0.65s 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      &:after {
        opacity: 1;
        transform: translateY(0);
        transition: 0.75s 0.1s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &:active {
      opacity: .3;
    }
  }

  @include respond-below('tablet') {
    height: 50px;
    font-size: 14px;
    letter-spacing: 1.12px;
  }
}
