.is-animate-show {
  .header-burger,
  .header-contact {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.header {
  height: 135px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0 40px 0 60px;
  transition: .3s ease-out;

  @include respond-below('desktop') {
    padding: 0 40px 0 50px;
  }

  @include respond-below('tablet') {
    height: 54px;
    padding: 0;

    &.is_sticky {
      .header-logo:before {
        opacity: 1;
      }
    }
  }
}

.header-overlay {
  height: 135px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
  transition: .3s ease-out;

  &.is_sticky {
    background-color: rgba(0, 0, 0, .9);
  }

  @include respond-below('tablet') {
    height: 54px;

    &.is_sticky {
      background-color: #0f131d;
      box-shadow: 0 1px 0 #161a23;
    }
  }
}

.header-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-burger {
  width: 120px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: translate3d(-80px, -80px, 0);
  transition: .8s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
  transition-delay: 1s;

  &.is_opened {
    .header-burger__icon {
      .icon-close {
        opacity: 1;
      }

      .icon-menu {
        opacity: 0;
      }
    }

    .burger {
      opacity: 0;
    }

    .header-burger__title {
      span {
        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          display: inline;
        }
      }
    }
  }

  &__icon {
    position: relative;
    margin-right: 30px;

    svg {
      fill: currentColor;
      transition: .1s opacity ease-out, .3s transform ease-out;
    }

    .icon-close {
      opacity: 0;
      @include abscenter();
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    font-family: 'NewLetterGothicC', sans-serif;
    line-height: 1;
    text-transform: uppercase;
    transition: .3s ease-out;

    span {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  @include respond-above('desktop') {
    &:hover {
      .icon-close {
        transform: rotate(180deg);
      }
    }
    
    &:active {
      opacity: .3;
    }

    &__icon {
      top: -2px;

      .icon-menu {
        opacity: 0;
      }
    }
  }

  @include respond-below('tablet') {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      margin: 0;

      .icon-menu {
        width: 15px;
        height: 10px;
      }

      .icon-close {
        width: 12px;
        height: 12px;
      }
    }

    &__title {
      display: none;
    }
  }
}

.header-logo {
  width: 64px;
  height: 64px;
  z-index: 2;
  @include abscenter();

  &__wrapper {
    width: inherit;
    height: inherit;
    transition: .3s .3s ease-out;
  }

  &__icon {
    width: 64px;
    height: 64px;
    opacity: 0;
    background-image: url('../images/burnaev-white-logo-animation.png');
    background-repeat: no-repeat;
    background-size: 1920px 64px;

    @include retina() {
      background-image: url('../images/burnaev-white-logo-animation@2x.png');
    }
  }

  @include respond-above('desktop') {
    transition: .3s ease-out;

    &:hover {
      opacity: .8;
    }

    &:active {
      opacity: .6;
    }
  }

  @include respond-below('tablet') {
    width: 40px;
    height: 40px;

    &:before {
      content: '';
      width: 98px;
      height: 61px;
      background-image: url('../images/m-logo-back.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: -5px;
      left: 50%;
      opacity: 0;
      transition: .3s ease-out;
      transform: translate3d(-50%, 0, 0);
    }

    &__icon {
      width: 40px;
      height: 40px;
      position: relative;
      background-image: url('../images/m-burnaev-white-logo-animation.png');
      background-size: 1200px 40px;

      @include retina() {
        background-image: url('../images/m-burnaev-white-logo-animation@2x.png');
      }
    }
  }
}

.header-contact {
  width: 120px;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transform: translate3d(80px, -80px, 0);
  transition: .8s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
  transition-delay: 1s;

  &__button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transition: .3s ease-out;

    &_mail {
      transition: .3s .3s transform ease-out, .3s color ease-out;
    }

    &:hover {
      .header-contact__title {
        opacity: 1;
        pointer-events: all;
      }
    }

    svg {
      fill: currentColor;
    }
  }

  &__title {
    position: absolute;
    top: 12px;
    right: 0;
    padding-right: 60px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'NewLetterGothicC', sans-serif;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    opacity: 0;
    pointer-events: none;
    transition: .3s ease-out;
  }

  &__link {
    transition: .3s ease-out;

    &:hover {
      opacity: .6;
    }

    &:active {
      opacity: .3;
    }
  }

  .icon-close {
    width: 12px;
    height: 12px;
    display: none;
  }

  @include respond-below('desktop') {
    &__title {
      display: none;
    }
  }

  @include respond-above('tablet') {
    .icon-m-mail-top,
    .icon-m-phone-top {
      display: none;
    }
  }

  @include respond-below('tablet') {
    margin-right: 12px;

    &__button {
      width: 34px;
      height: 54px;

      .icon-mail-top,
      .icon-phone-top {
        display: none;
      }
    }
  }
}
