.is-animate-show {
  .title__text {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.title {
  font-size: 55px;
  font-weight: 600;
  line-height: 65px;
  text-align: center;
  font-family: 'NewYorkSmall', serif;

  &__el {
    overflow: hidden;
    display: block;

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        &:before,
        &:after,
        .title__text {
          transition-delay: ($i * .2s) + .2s;
        }
      }
    }
  }

  &__text {
    display: block;
    opacity: 0;
    transform: translate3d(0, 80px, 0);
    transition: .8s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;
  }

  @include respond-below('tablet') {
    font-size: 30px;
    line-height: 35px;
  }
}
