.is-modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px;
  font-size: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  background-color: transparent;
  z-index: 11;
  display: none;
  transition: .3s ease-out;

  &.is_open {
    display: block;
  }

  &.is_visible {
    background-color: #000;

    .modal-box {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-delay: .3s;
    }
  }

  &:before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  @include respond-below('desktop') {
    padding: 75px;
  }

  @include respond-below('tablet') {
    padding: 15px;
  }
}

.modal-box {
  width: 100%;
  max-width: 1060px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #000;
  font-size: 14px;
  position: relative;
  padding: 135px 160px;
  background-color: #fff;
  border: 1px solid #eeeeee;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  transition: .3s transform cubic-bezier(.165,.64,.44,1), .3s opacity ease-out;

  &_theme_success {
    max-width: 850px;
    padding: 70px;
    border-radius: 20px;
  }

  @include respond-below('desktop') {
    padding: 50px;
  }

  @include respond-below('tablet') {
    padding: 40px;

    &_theme_success {
      padding: 40px 25px;
    }
  }
}

.modal-close {
  width: 72px;
  height: 72px;
  position: absolute;
  top: 64px;
  right: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .05);
  border-radius: 50%;

  .icon-close {
    opacity: .2;
    transition: .3s ease-out;
  }

  @include respond-above('desktop') {
    &:hover {
      .icon-close {
        transform: rotate(180deg);
      }
    }
  }

  @include respond-below('desktop') {
    top: 40px;
    right: 30px;
    background-color: transparent;
  }

  @include respond-below('tablet') {
    width: 40px;
    height: 40px;
    top: 35px;
    right: 20px;

    .icon-close {
      width: 12px;
      height: 12px;
    }
  }
}

.modal-detail {
  &__title {
    color: #111111;
    font-family: 'NewYorkSmall', serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.64px;
    margin-bottom: 35px;
  }

  &__position {
    height: 62px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'NewLetterGothicC', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 65px;
  }

  &__form {
    margin-top: 160px;
  }

  @include respond-above('desktop') {
    padding-bottom: 50px;
  }

  @include respond-below('desktop') {
    &__title {
      font-size: 45px;
      line-height: 45px;
    }

    &__position {
      margin-bottom: 45px;
    }

    &__form {
      margin-top: 50px;
    }
  }

  @include respond-below('tablet') {
    &__title {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 30px;
    }

    &__position {
      height: 50px;
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}
