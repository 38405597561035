.is-phone-open {
  .panel-phone {
    opacity: 1;
    pointer-events: all;
    transform: translate3d(0, 0, 0);
    transition-delay: .2s;

    &__text {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-delay: .4s;
    }
  }

  @include respond-above('tablet') {
    .header-contact__button_mail {
      transform: translate3d(-350px, 0, 0);
      transition-delay: 0s;
    }

    @include respond-below('desktop') {
      .header-logo__wrapper {
        opacity: .1;
        transition-delay: 0s;
      }
    }
  }
  
  @include respond-below('tablet') {
    .header-contact__button {
      .icon-close {
        display: block;
      }

      .icon-m-phone-top {
        display: none;
      }
    }
  }
}

.panel-phone {
  height: 232px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  will-change: transform, opacity;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, -10px, 0);
  transition: .3s ease-out;

  &__list {
    display: flex;
    height: 100%;
  }

  &__elem {
    width: 150px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'NewLetterGothicC', sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin-left: 30px;
  }

  &__icon {
    margin-bottom: 20px;

    .icon-phone-footer {
      width: 12px;
      height: 22px;
      fill: #fff;
    }
  }

  &__number {
    margin-bottom: 20px;
  }

  &__text {
    color: #be9d7f;
    opacity: 0;
    transition: .3s ease-out;
    transform: translate3d(0, -10px, 0);
  }

  @include respond-above('tablet') {
    &__list {
      justify-content: flex-end;
      padding: 60px 100px 0 0;
      background-color: #000;
    }

    &__icon {
      display: none;
    }
  }

  @include respond-below('tablet') {
    padding: 54px 0 0;
    background-color: #0f131d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &__list {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    &__elem {
      width: 50%;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.96px;
      margin: 0;
      padding-top: 25px;

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}