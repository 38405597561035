.form-field {
  &__input {
    margin-bottom: -1px;
  }
}

.is-error {
  .form-input {
    border-color: #f27958;
    z-index: 1;
  }
}

.form-input {
  width: 100%;
  height: 60px;
  font-family: 'NewLetterGothicC', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1.44px;
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  @include placeholder(#000, 18px);
  
  &:focus {
    border-color: #000000;
    z-index: 1;
  }

  @include respond-below('tablet') {
    height: 50px;
    font-size: 14px;
    letter-spacing: .5px;
    @include placeholder(#000, 14px);
  }
}

.form-checkbox {
  min-height: 34px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  &__text {
    position: relative;
    display: block;
    font-family: 'NewLetterGothicC', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.57px;
    padding-left: 50px;
    padding-top: 7px;

    &:before {
      content: '';
      width: 34px;
      height: 34px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #f2f2f2;
      border-radius: 50%;
      transition: .3s ease-out;
    }

    &:after {
      content: '';
      width: 13px;
      height: 11px;
      position: absolute;
      top: 12px;
      left: 11px;
      opacity: 0;
      background-image: url('../images/tick.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      transition: .3s ease-out;
    }
  }

  input {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + .form-checkbox__text {
      &:after {
        opacity: 1;
      }
    }
  }

  @include respond-above('desktop') {
    &:hover {
      .form-checkbox__text:before {
        background-color: #ebebeb;
      }
    }
  }

  @include respond-below('tablet') {
    &__text {
      font-size: 12px;
      line-height: 14px;
      padding-left: 40px;

      &:before {
        width: 26px;
        height: 26px;
      }

      &:after {
        width: 10px;
        height: 8px;
        top: 9px;
        left: 8px;
      }
    }
  }
}
