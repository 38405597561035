@mixin placeholder($c, $z) {
  &::-webkit-input-placeholder {
    color: $c;
    font-size: $z;
  }

  &:-moz-placeholder {
    color: $c;
    font-size: $z;
  }

  &::-moz-placeholder {
    color: $c;
    font-size: $z;
  }

  &:-ms-input-placeholder {
    color: $c;
    font-size: $z;
  }
}
