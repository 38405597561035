.icon-menu {
  width: 20px;
  height: 12px;
}

.icon-close {
  width: 18px;
  height: 18px;
}

.icon-mail-top {
  width: 20px;
  height: 16px;
}

.icon-m-mail-top {
  width: 15px;
  height: 12px;
}

.icon-phone-top {
  width: 19px;
  height: 27px;
}

.icon-m-phone-top {
  width: 16px;
  height: 22px;
}

.icon-play {
  width: 13px;
  height: 14px;
}

.icon-scrolldown {
  width: 16px;
  height: 9px;
}

.icon-phone-footer {
  width: 17px;
  height: 30px;
}

.icon-copyright {
  width: 15px;
  height: 15px;
}
