.burger {
	width: 100px;
	height: 20px;
	position: relative;
	cursor: pointer;
	display: flex;
	flex-direction: column;
  @include abscenter();

	@include respond-above('desktop') {
		&:hover {
			.burger__line {
				&:first-child {
					&:before {
            transition: .15s cubic-bezier(.48,0,.12,1);
          }

					&:after {
            transition: .15s .15s cubic-bezier(.48,0,.12,1);
          }
        }

				&:nth-child(2) {
					&:before {
            transition: .15s .15s cubic-bezier(.48,0,.12,1);
          }

					&:after {
            transition: .15s .3s cubic-bezier(.48,0,.12,1);
          }
        }

				&:before {
          transform: translate3d(100%, 0, 0);
        }

				&:after {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    &__line {
      width: 20px;
      height: 2px;
      margin: 4px 0;
      overflow: hidden;
      position: relative;
      transition: .15s cubic-bezier(.48,0,.12,1);
  
      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
  
      &:after {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }

	@include respond-below('desktop') {
    display: none;
  }
}