.is-menu-open {
  .menu {
    opacity: 1;
    pointer-events: all;
  }

  .menu-overlay {
    height: 100%;
    transition-delay: 0s;
  }

  .menu-nav__elem {
    &:before {
      opacity: .1;
    }
  }

  .menu-nav__line {
    width: 100%;
  }

  .menu-address,
  .menu-nav__title {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include respond-above('tablet') {
    .panel-phone__list {
      background-color: transparent;
    }
  }

  @include respond-below('tablet') {
    .panel-phone {
      background-color: #0f131d;
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease-out;

  &_visible {
    display: flex;
  }
}

.menu-overlay {
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0f131d;
  transition: .7s .5s cubic-bezier(.165, .84, .44, 1);
}

.menu-container {
  width: 100%;

  @include respond-below('desktop') {
    padding: 0 105px;
  }

  @include respond-below('tablet') {
    padding: 0 20px;
  }
}

.menu-nav {
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
  counter-reset: list;

  &__elem {
    position: relative;

    &:before {
      counter-increment: list;
      content: '0' counter(list) '. ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
      font-family: 'NewLetterGothicC', sans-serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.44px;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: .5s ease-out;
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        &:before,
        .menu-nav__line,
        .menu-nav__title {
          transition-delay: ($i * .15s) + .5s;
        }
      }
    }
  }

  &__line {
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(255, 255, 255, .1);
    transition: .5s width cubic-bezier(.165,.84,.44,1), .3s background ease-out;

    &:before {
      content: '';
      width: 0;
      height: 1px;
      background-color: #fff;
      transition: .5s cubic-bezier(.165,.84,.44,1);
      @include abscenter();

      right: auto;
    }
  }

  &__link {
    font-family: 'NewYorkSmall', serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 60px;
    display: inline-block;
    overflow: hidden;
    letter-spacing: 1.8px;
  }

  &__title {
    display: block;
    position: relative;
    padding: 0 20px;
    z-index: 2;
    background-color: #0f131d;
    transition: .5s cubic-bezier(.165,.84,.44,1);
		transform: translate3d(0, 70px, 0);
  }

  @include respond-above('desktop') {
    &__link {
      &:hover {
        & + .menu-nav__line:before {
          width: 100%;
        }
      }
    }
  }

  @include respond-below('tablet') {
    &__elem {
      &:before {
        display: none;
      }
    }

    &__link {
      font-size: 30px;
      line-height: 40px;
    }
  }
}

.menu-address {
  color: #be9d7f;
  font-family: 'NewLetterGothicC', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.44px;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: .5s 1.5s cubic-bezier(.165,.84,.44,1);
  transform: translate3d(0, 40px, 0);

  @include respond-below('tablet') {
    font-size: 14px;
    line-height: 16px;
    bottom: 30px;
  }
}
