@font-face {
  font-family: 'NewLetterGothicC';
  src: url('../fonts/NewLetterGothicC.eot');
  src: url('../fonts/NewLetterGothicC.eot?#iefix')format('embedded-opentype'),
    url('../fonts/NewLetterGothicC.woff2') format('woff2'),
    url('../fonts/NewLetterGothicC.woff') format('woff'),
    url('../fonts/NewLetterGothicC.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NewYorkSmall';
  src: url('../fonts/NewYorkSmall-Semibold.eot');
  src: url('../fonts/NewYorkSmall-Semibold.eot?#iefix')format('embedded-opentype'),
    url('../fonts/NewYorkSmall-Semibold.woff2') format('woff2'),
    url('../fonts/NewYorkSmall-Semibold.woff') format('woff'),
    url('../fonts/NewYorkSmall-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
