.services-card {
  height: 490px;
  padding: 90px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0a0d14;

  &__title {
    font-family: 'NewYorkSmall', serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
  }

  @include respond-above('desktop') {
    transition: .3s ease-out;
    
    &:hover {
      background-color: #0b1117;
    }
  }

  @include respond-below('desktop') {
    height: 292px;
    padding: 50px;

    &__title {
      font-size: 35px;
      line-height: 35px;
    }
  }

  @include respond-below('tablet') {
    height: 230px;
    padding: 40px;

    &__title {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
