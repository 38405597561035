.modal-success {
  text-align: center;

  &__icon {
    margin-bottom: 45px;
  }

  &__text {
    font-size: 27px;
    color: #9f7650;
    margin-bottom: 70px;
  }

  &__close {
    font-size: 18px;
    font-family: 'NewLetterGothicC', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.44px;
  }

  @include respond-below('tablet') {
    &__icon {
      margin-bottom: 30px;

      img {
        width: 33px;
      }
    }

    &__text {
      font-size: 16px;
      margin-bottom: 60px;
    }

    &__close {
      font-size: 14px;
      letter-spacing: 1.12px;
    }
  }
}