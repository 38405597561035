.feedback {
  color: #000000;
  padding: 80px;
  background-color: #fff;

  @include respond-below('desktop') {
    padding: 70px 50px 50px;
  }

  @include respond-below('tablet') {
    padding: 40px;
  }
}

.feedback-title {
  margin-bottom: 75px;

  @include respond-below('desktop') {
    margin-bottom: 70px;
  }

  @include respond-below('tablet') {
    margin-bottom: 30px;
  }
}

.feedback-form {
  max-width: 730px;
  margin: 0 auto;
}

.feedback-agree {
  margin: 35px 0;

  @include respond-below('tablet') {
    margin: 25px 0;
  }
}

.feedback-footer {
  @include respond-above('desktop') {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 45px;

    &__checkbox {
      padding-left: 40px;

      .form-checkbox__text {
        font-size: 15px;
        letter-spacing: 1px;
      }
    }
  }

  @include respond-below('desktop') {
    &__checkbox {
      margin: 35px 0;
    }

    &__submit {
      .button {
        width: 100%;
      }
    }
  }

  @include respond-below('tablet') {
    &__checkbox {
      margin: 25px 0;
    }
  }
}
