.is-menu-services-open {
  .header {
    color: #000;
  }

  .header-logo__icon {
    background-image: url('../images/burnaev-brown-logo-animation.png');

    @include retina() {
      background-image: url('../images/burnaev-brown-logo-animation@2x.png');
    }
  }

  .menu-services {
    opacity: 1;
    pointer-events: all;
  }

  .menu-services-overlay {
    height: 100%;
    transition-delay: 0s;
  }

  .menu-services-nav__elem {
    &:before {
      opacity: 1;
    }
  }

  .menu-services-nav__line {
    width: 100%;
  }

  .menu-services-nav__title {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include respond-above('tablet') {
    .panel-phone__list {
      background-color: transparent;
    }
  }

  @include respond-below('tablet') {
    .panel-phone {
      background-color: #fff;
    }
  }
}

.menu-services {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease-out;

  &_visible {
    display: flex;
  }
}

.menu-services-overlay {
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  transition: .7s .5s cubic-bezier(.165, .84, .44, 1);
}

.menu-services-container {
  width: 100%;
}

.menu-services-nav {
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  counter-reset: list;

  &__elem {
    position: relative;

    &:before {
      counter-increment: list;
      content: '0' counter(list) '. ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
      font-family: 'NewLetterGothicC', sans-serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.44px;
      color: #444;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: .5s ease-out;
    }

    &:first-child,
    &:nth-child(5) {
      padding-left: 40px;
    }

    &:nth-child(2),
    &:nth-child(6) {
      padding-left: 120px;
    }

    &:nth-child(3) {
      padding-left: 300px;
    }

    &:nth-child(4) {
      text-align: right;
      padding-right: 30px;
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        &:before,
        .menu-services-nav__line,
        .menu-services-nav__title {
          transition-delay: ($i * .15s) + .5s;
        }
      }
    }
  }

  &__line {
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, .1);
    transition: .5s width cubic-bezier(.165,.84,.44,1), .3s background ease-out;

    &:before {
      content: '';
      width: 0;
      height: 1px;
      background-color: #000;
      transition: .5s cubic-bezier(.165,.84,.44,1);
      @include abscenter();

      right: auto;
    }
  }

  &__link {
    font-family: 'NewYorkSmall', serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 60px;
    position: relative;
    z-index: 2;
    display: inline-block;
    overflow: hidden;
    letter-spacing: 1.2px;
  }

  &__title {
    display: block;
    padding: 0 20px;
    background-color: #fff;
    transition: .5s cubic-bezier(.165,.84,.44,1);
		transform: translate3d(0, 70px, 0);
  }

  @include respond-above('desktop') {
    &__link {
      &:hover {
        & + .menu-services-nav__line:before {
          width: 100%;
        }
      }
    }
  }
}
