/**
 * Variables
 */

 @import 'settings/colors';
 @import 'settings/breakpoints';

 /**
  * Functions, mixins
  */

 @import 'tools/functions';
 @import 'tools/mixins';


/**
 * Basic rules
 */

@import 'base/base';
@import 'base/fonts';

/**
 * Vendor
 */

@import 'vendor/import';

/**
 * Components
 */

@import 'components/preloader';
@import 'components/icons-size';
@import 'components/button';
@import 'components/container';
@import 'components/header';
@import 'components/panel-phone';
@import 'components/burger';
@import 'components/menu';
@import 'components/menu-services';
@import 'components/logo';
@import 'components/title';
@import 'components/footer';
@import 'components/hero';
@import 'components/link';
@import 'components/services-grid';
@import 'components/services-card';
@import 'components/feedback';
@import 'components/form';
@import 'components/modal';
@import 'components/article';
@import 'components/services';
@import 'components/service-detail';
@import 'components/aos';
@import 'components/modal-success';