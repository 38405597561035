.container {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 30px;

  @include respond-below('tablet') {
    padding: 0 15px;
  }
}
